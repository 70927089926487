/** @format */

import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import { FunctionComponent as FC, ReactNode } from 'react';
import { StyledButton } from './Button/StyledButton';
import { H1, H2, H3, H4, P, Subtitle } from './Typography';
import { Entry } from 'contentful';
import { MagicLink } from 'src/utils/magicLink';
import { ExternalLink, InternalLink } from '../../types/page';
import { Image } from '../Image';
import { ScriptBlock } from '@/utils/scriptBlock';

export const RichText: FC<Props> = ({ text, customOptions }) => {
  return documentToReactComponents(text, customOptions ?? DEFAULT_OPTIONS);
};

type Props = {
  text: Document;
  customOptions?: Options;
};

const DEFAULT_OPTIONS: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <Subtitle>{children}</Subtitle>,
    [BLOCKS.HEADING_6]: (node, children) => <Subtitle>{children}</Subtitle>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <P>{children}</P>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      if (node.data.target.sys.contentType.sys.id === 'scriptBlock') {
        return (
          <p id={node.data.target.fields.title}>
            {ScriptBlock({ code: node.data.target.fields.script, title: node.data.target.fields.title })}
          </p>
        );
      }

      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;
        return (
          <StyledButton
            item={item}
            position={item.fields.position ?? 'right'}
            variant={item.fields.variant ?? 'bgTeal'}
            elementType="magic"
          >
            {item.fields.label}
          </StyledButton>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;

        return <MagicLink item={item} />;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.fields.file.contentType.includes('image')) {
        return <Image image={node.data.target} />;
      }
    },
  },
};
