/** @format */

import { Entry } from 'contentful';
import { LinkProps } from 'next/link';
import { useMemo } from 'react';
import styled from 'styled-components';
import { GTMCTAClick } from '../../../lib/google-tag-manager';
import { ExternalLink, InternalLink } from '../../../types/page';
import { MagicLink } from '../../../utils/magicLink';
import { InboundLink, OutboundLink } from '../Link';
import { P } from '../Typography';
import { SvgLeft, SvgRight } from './SvgArrows';

function Middleware(props: MiddlewareProps): JSX.Element {
  if (props.elementType === 'inbound') {
    const { locale, ...rest } = props;
    return <InboundLink {...rest} locale={locale} />;
  } else if (props.elementType === 'outbound') {
    const { ...rest } = props;
    return <OutboundLink {...rest} />;
  } else if (props.elementType === 'button') {
    const { className, elementType, ...rest } = props;
    return <button {...props} />;
  } else {
    const { item, locale, onClick, ...rest } = props;

    const handleClick = () => {
      GTMCTAClick(item.fields.gtmType ?? '', item.fields.gtmLabel ?? '');
      //@ts-ignore
      onClick && onClick();
    };

    return <MagicLink {...rest} item={item!} locale={locale} onClick={handleClick} />;
  }
}

export const StyledButton = (props: StyledButtonProps) => {
  const { variant, position, children, ...rest } = props;

  const label = useMemo(() => {
    if (children) return children;
    if (props.elementType === 'button') return undefined;
    if (props.elementType === 'inbound') return undefined;
    if (props.elementType === 'outbound') return undefined;
    if (props.elementType === 'magic') return props.item.fields.label;
  }, [props]);

  return (
    <Button $position={position} $variant={variant || 'bgWhite'} {...rest}>
      {position === 'left' && <SvgLeft position={position} variant={variant} />}
      {label && (
        <P as="span" style={{ fontWeight: 600 }}>
          {label}
        </P>
      )}
      {(position === 'right' || position === 'down') && <SvgRight position={position} variant={variant} />}
    </Button>
  );
};

const Button = styled(Middleware)<StyledPropsLink>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: auto;
  height: auto;
  border: none;
  border-radius: 2.25rem;
  transition: background 0.5s cubic-bezier(0.36, 0.33, 0, 1);
  cursor: pointer;

  padding: ${({ $variant }) =>
    $variant === 'yellow' || $variant === 'teal' || $variant === 'white' || $variant === 'secondaryWhite'
      ? 0
      : '0.75rem 1.25rem'};
  background: ${({ theme, $variant }) =>
    $variant === 'bgYellow'
      ? theme.color.yellow
      : $variant === 'bgTeal' || $variant === 'bgSecondaryTeal'
      ? theme.color.teal.dark
      : $variant === 'bgWhite'
      ? theme.color.white
      : 'none'};
  color: ${({ theme, $variant }) =>
    $variant === 'bgYellow'
      ? theme.color.teal.dark
      : $variant === 'bgWhite'
      ? theme.color.teal.dark
      : $variant === 'yellow'
      ? theme.color.yellow
      : $variant === 'teal'
      ? theme.color.teal.dark
      : $variant === 'white'
      ? theme.color.white
      : $variant === 'secondaryWhite'
      ? theme.color.white
      : theme.color.white};

  #svgArrowDefault {
    stroke: ${({ theme, $variant, $position }) =>
      $variant === 'bgWhite' || $variant === 'teal' || $variant === 'bgYellow' || $position === 'down'
        ? theme.color.teal.dark
        : $variant === 'yellow'
        ? theme.color.yellow
        : $variant === 'bgTeal' ||
          $variant === 'white' ||
          $variant === 'bgSecondaryTeal' ||
          $variant === 'secondaryWhite'
        ? theme.color.white
        : theme.color.blue};
    transition: all 0.3s ease-in-out;
  }

  #svgArrowHidden {
    stroke: ${({ theme, $variant, $position }) =>
      $variant === 'bgWhite' || $variant === 'teal' || $variant === 'bgYellow' || $position === 'down'
        ? theme.color.teal.dark
        : $variant === 'yellow'
        ? theme.color.yellow
        : $variant === 'bgTeal' ||
          $variant === 'white' ||
          $variant === 'bgSecondaryTeal' ||
          $variant === 'secondaryWhite'
        ? theme.color.white
        : theme.color.blue};
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate(-30px, 0);
  }
  #circle {
    fill: ${({ theme, $position }) => ($position === 'down' ? theme.color.teal.light : null)};
    stroke: ${({ theme, $variant, $position }) =>
      $position === 'down'
        ? theme.color.teal.light
        : $variant === 'yellow'
        ? theme.color.yellow
        : $variant === 'bgWhite' || $variant === 'teal' || $variant === 'bgYellow'
        ? theme.color.teal.dark
        : $variant === 'bgTeal' ||
          $variant === 'white' ||
          $variant === 'bgSecondaryTeal' ||
          $variant === 'secondaryWhite'
        ? theme.color.white
        : theme.color.blue};
  }
  &:hover {
    #svgArrowDefault {
      stroke: ${({ theme, $variant }) =>
        $variant === 'bgWhite'
          ? theme.color.teal.light
          : $variant === 'bgTeal' || $variant === 'white' || $variant === 'bgYellow'
          ? theme.color.white
          : $variant === 'yellow'
          ? theme.color.yellow
          : $variant === 'bgSecondaryTeal' || $variant === 'teal'
          ? theme.color.teal.dark
          : theme.color.accent.orange};
      transform: translate(30px, 0);
    }
    #svgArrowHidden {
      stroke: ${({ theme, $variant }) =>
        $variant === 'bgWhite'
          ? theme.color.teal.light
          : $variant === 'bgTeal' || $variant === 'white' || $variant === 'bgYellow'
          ? theme.color.white
          : $variant === 'yellow'
          ? theme.color.yellow
          : $variant === 'bgSecondaryTeal' || $variant === 'teal'
          ? theme.color.teal.dark
          : theme.color.accent.orange};
      transform: translate(0, 0);
    }
    #circle {
      stroke: ${({ theme, $variant, $position }) =>
        $position === 'down'
          ? theme.color.teal.light
          : $variant === 'yellow'
          ? theme.color.yellow
          : $variant === 'bgWhite'
          ? theme.color.teal.light
          : $variant === 'bgTeal' || $variant === 'white' || $variant === 'bgYellow'
          ? theme.color.white
          : $variant === 'bgSecondaryTeal' || $variant === 'teal'
          ? theme.color.teal.dark
          : theme.color.accent.orange};
    }
    &:not(:hover) {
      transform: translate(0, 0);
      transition: all 0.3s ease-in-out;
    }

    background: ${({ theme, $variant }) =>
      $variant === 'bgYellow'
        ? theme.color.teal.light
        : $variant === 'bgTeal'
        ? theme.color.teal.light
        : // : $variant === 'yellow'
        // ? theme.color.yellow
        $variant === 'bgSecondaryTeal'
        ? theme.color.yellow
        : $variant === 'bgWhite'
        ? theme.color.white
        : 'none'};
    color: ${({ theme, $variant }) =>
      $variant === 'bgYellow'
        ? theme.color.white
        : $variant === 'bgTeal'
        ? theme.color.white
        : $variant === 'yellow'
        ? theme.color.yellow
        : $variant === 'bgWhite' || $variant === 'bgSecondaryTeal'
        ? theme.color.teal.dark
        : $variant === 'teal' || $variant === 'secondaryWhite'
        ? theme.color.teal.light
        : $variant === 'white' && theme.color.teal.dark};
  }
`;

type MiddlewareOwnProps = {
  children?: React.ReactNode;
};

type InboundLinkProps = MiddlewareOwnProps &
  Omit<LinkProps, keyof MiddlewareOwnProps> & {
    elementType: 'inbound';
    locale?: string;
    item?: never;
  };

type OutboundLinkProps = MiddlewareOwnProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof MiddlewareOwnProps> & {
    elementType: 'outbound';
    locale?: string;
    item?: never;
  };

type ButtonProps = MiddlewareOwnProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof MiddlewareOwnProps> & {
    elementType: 'button';
  };

type MagicProps = MiddlewareOwnProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof MiddlewareOwnProps> & {
    elementType: 'magic';
    locale?: string;
    item: Entry<InternalLink['fields'] | ExternalLink['fields']>;
  };

type MiddlewareProps = InboundLinkProps | OutboundLinkProps | ButtonProps | MagicProps;

export type StyledPropsLink = {
  $variant: Variant;
  $position: Position;
};

type StyledButtonProps = {
  variant: Variant;
  position: Position;
} & MiddlewareProps;

type Variant = 'bgYellow' | 'bgTeal' | 'bgWhite' | 'bgSecondaryTeal' | 'teal' | 'white' | 'secondaryWhite' | 'yellow';
type Position = 'left' | 'right' | 'down' | 'none';
