/** @format */

import { Asset } from 'contentful';
import NextImage, { ImageProps } from 'next/image';
import { FunctionComponent as FC, useState } from 'react';
import styled from 'styled-components';

export const Image: FC<Props> = ({ image, placeholder = true, thumb = false, ...rest }) => {
  const { description, title = '', file } = image!.fields;
  const { details, url } = file;
  const { width: nativeWidth, height: nativeHeight } = details.image!;
  const { width, height, ...other } = rest;

  const imageWidth = thumb ? 800 : width ?? nativeWidth;
  const imageHeight = height ?? nativeHeight;

  const [isLoaded, setIsLoaded] = useState(false);

  const imageLoader = ({ src, width = 3840 }: { src: string; width: number; height?: number }) => {
    return `https:${src}?w=${thumb ? 800 : width}&q=100&fm=webp${thumb ? `&fit=thumb` : ''}`;
  };

  const isSVG = image.fields.file.contentType === 'image/svg+xml';

  if (!placeholder && isSVG)
    return (
      <ImageContainer $height={imageHeight as number} $width={imageWidth as number}>
        <NextImage
          loader={imageLoader}
          src={url}
          width={imageWidth}
          height={imageHeight}
          sizes={`
          (max-width: 768px) 100vw, ${imageWidth}px
          `}
          alt={description !== '' ? description : title}
          {...other}
        />
      </ImageContainer>
    );

  if (!placeholder && !isSVG)
    return (
      <NextImage
        loader={imageLoader}
        src={url}
        width={imageWidth}
        height={imageHeight}
        sizes={`
          (max-width: 768px) 100vw, ${imageWidth}px
          `}
        alt={description !== '' ? description : title}
        {...other}
      />
    );

  return (
    <ImageContainer $height={imageHeight as number} $width={imageWidth as number}>
      {!isLoaded && <Loader className="image-loader" />}
      <NextImage
        loader={imageLoader}
        onLoad={() => setIsLoaded(true)}
        src={url}
        width={imageWidth}
        height={imageHeight}
        sizes={`
          (max-width: 768px) 100vw, ${imageWidth}px
          `}
        alt={description !== '' ? description : title}
        {...other}
      />
    </ImageContainer>
  );
};

type Props = {
  image: Asset;
  thumb?: boolean;
  placeholder?: boolean;
} & Omit<ImageProps, 'alt' | 'src' | 'placeholder'>;

const ImageContainer = styled.div<{ $height: number; $width: number }>`
  position: relative;
  min-width: 0;
  min-height: 0;
  width: ${({ $width }) => `min(${$width}px, 100%)`};
  max-width: 100%;
  height: 100%;
  img {
    object-position: center;
  }
`;

const Loader = styled.div`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: ease;
  border-radius: 1rem;
  position: absolute;
  inset: 0;

  @keyframes placeholder {
    0% {
      background-color: #eeeeee3a;
    }
    50% {
      background-color: #10101039;
    }
    100% {
      background-color: #eeeeee3a;
    }
  }
`;
