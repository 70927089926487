/** @format */

import { useEffect } from 'react';

export const ScriptBlock = ({ code, title }: { code: string; title: string }) => {
  useEffect(() => {
    const parseScript = (scriptStr: string): HTMLScriptElement | null => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(scriptStr, 'text/html');
      const scriptTag = doc.querySelector('script');
      return scriptTag as HTMLScriptElement | null;
    };

    const scriptTag = parseScript(code);

    if (scriptTag) {
      const script = document.createElement('script');
      const scriptParent = document.getElementById(title);
      Array.from(scriptTag.attributes).forEach(attr => {
        script.setAttribute(attr.name, attr.value);
      });
      if (scriptParent) scriptParent.appendChild(script);

      return () => {
        if (scriptTag.id) {
          const existingScript = document.getElementById(scriptTag.id);
          if (existingScript && existingScript.parentNode) {
            existingScript.parentNode.removeChild(existingScript);
          }
        }
      };
    }
  }, [code]);

  return null;
};
