/** @format */

import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { StyledPropsLink } from './StyledButton';

export const SvgLeft: FC<Props> = ({ position, variant }) => {
  return (
    <Svg
      $position={position}
      $variant={variant}
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1247_4470)">
        <path
          id="circle"
          stroke="red"
          d="M14.9101 1.43956C7.24963 1.43956 1.03963 7.64957 1.03963 15.31C1.03963 22.9704 7.24963 29.1804 14.9101 29.1804C22.5705 29.1804 28.7805 22.9704 28.7805 15.31C28.7805 7.64957 22.5705 1.43956 14.9101 1.43956Z"
          strokeWidth="2.0792"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          transform="translate(0 0)"
          id="svgArrowDefault"
          d="M8.72009 15.31L21.4552 15.31M21.4552 15.31L17.8166 19.1306M21.4552 15.31L17.8166 11.4895"
          strokeWidth="2.0792"
          strokeLinecap="square"
        />
        <path
          transform="translate(-30 0)"
          id="svgArrowHidden"
          d="M8.72009 15.31L21.4552 15.31M21.4552 15.31L17.8166 19.1306M21.4552 15.31L17.8166 11.4895"
          strokeWidth="2.0792"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1247_4470">
          <rect width="29.8201" height="29.8201" fill="white" transform="translate(29.8201 30.2201) rotate(180)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export const SvgRight: FC<Props> = ({ position, variant }) => {
  return (
    <Svg
      $position={position}
      $variant={variant}
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1247_4470)">
        <path
          id="circle"
          stroke="red"
          d="M14.9101 1.43956C7.24963 1.43956 1.03963 7.64957 1.03963 15.31C1.03963 22.9704 7.24963 29.1804 14.9101 29.1804C22.5705 29.1804 28.7805 22.9704 28.7805 15.31C28.7805 7.64957 22.5705 1.43956 14.9101 1.43956Z"
          strokeWidth="2.0792"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          transform="translate(0 0)"
          id="svgArrowDefault"
          d="M8.72009 15.31L21.4552 15.31M21.4552 15.31L17.8166 19.1306M21.4552 15.31L17.8166 11.4895"
          strokeWidth="2.0792"
          strokeLinecap="square"
        />
        <path
          transform="translate(-30 0)"
          id="svgArrowHidden"
          d="M8.72009 15.31L21.4552 15.31M21.4552 15.31L17.8166 19.1306M21.4552 15.31L17.8166 11.4895"
          strokeWidth="2.0792"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1247_4470">
          <rect width="29.8201" height="29.8201" fill="white" transform="translate(29.8201 30.2201) rotate(180)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export const Svg = styled.svg<StyledPropsLink>`
  transform: ${({ $position }) =>
    $position === 'left' ? 'rotate(180deg)' : $position === 'down' ? 'rotate(90deg)' : null};
`;

type Props = {
  variant: 'bgYellow' | 'bgTeal' | 'bgWhite' | 'bgSecondaryTeal' | 'teal' | 'white' | 'secondaryWhite' | 'yellow';
  position: 'left' | 'right' | 'down';
};
